<template>
  <div>
    <div id="helpCenter" class="bg-light">
      <b-container fluid>
        <b-row>
          <b-col md="12" class="d-flex justify-content-between">
            <div class="logo mb-1">
              <img
                src="../../assets/images/YT1.png"
                alt=""
                class="img-fluid"
                style="width: 50px; height: 50px"
              />
            </div>
            <div class="backBtn">
              <b-button
                size="md"
                pill
                @click="$router.push({ name: 'helpCenter' })"
                style="
                  font-size: 14px;
                  font-weight: 600;
                  background-color: #1ea2ed;
                  border-color: #1ea2ed;
                "
                ><i class="fas fa-arrow-left"></i
              ></b-button>
            </div>
          </b-col>
          <b-col md="4">
            <br /><br /><br /><br /><br /><br />
            <b-list-group>
              <b-list-group-item
                :active="activefaq == faqIndex ? true : false"
                v-for="(faq, faqIndex) in faqOptions"
                :key="faqIndex"
                @click="switchFaqSection(faq.array, faqIndex)"
              >
                {{ faqIndex + 1 }}) {{ faq.text }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col md="8">
            <div class="helpCenter">
              <h3>Help Center Documents for Ezygo.app Academic Management Application</h3>
              <br /><br />
              <div class="accounts bg-white p-3 rounded border border-info">
                <div
                  class="account"
                  v-for="(account, accountIndex) in faqQuestionsArray"
                  :key="accountIndex"
                >
                  <div class="ques">
                    <p class="btn p-0 m-0" v-b-toggle="'question' + accountIndex">
                      {{ account.question }}
                    </p>
                    <b-collapse :id="'question' + accountIndex" class="mt-2">
                      <b-card>
                        <div class="ans">
                          <div v-if="account.answer && !account.answer.answer1">
                            <p v-html="account.answer">{{ account.answer }}</p>
                          </div>
                          <div
                            v-else
                            v-for="(ans, value, ansIndex) of account.answer"
                            :key="ansIndex"
                          >
                            <p v-if="ansIndex % 2 == 0" v-html="ans">
                              {{ ans }}
                            </p>
                            <div class="image" v-if="ansIndex % 2 != 0 && ans != null">
                              <img
                                :src="getImgUrl(ans)"
                                :alt="'img' + ansIndex"
                                class="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="image" v-if="account.image != null">
                          <img
                            :src="getImgUrl(account.image)"
                            :alt="'img' + accountIndex"
                            class="img-fluid"
                          />
                        </div>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import HelpCenterData from "./HelpCenterData.js";
HelpCenterData;
export default {
  data() {
    return {
      activefaq: 0,
      faqOptions: [
        {
          text: "Getting started with ezygo.app",
          array: HelpCenterData.createAccountQuestions,
        },
        {
          text: "Creating an institute in ezygo.app",
          array: HelpCenterData.createInstituteQuestions,
        },
        // {
        //   text: "Creating Groups, Subgroups and Courses",
        //   array: HelpCenterData.createGroupSubgroupQuestions,
        // },
      ],
      faqQuestionsArray: HelpCenterData.createAccountQuestions,
    };
  },
  methods: {
    switchFaqSection(faqArray, faqIndex) {
      this.faqQuestionsArray = faqArray;
      this.activefaq = faqIndex;
    },
    getImgUrl(imgName) {
      return require("../../assets/images/help_center/" + imgName);
    },
  },
};
</script>

<style >
.helpCenter {
  padding: 20px;
}
#helpCenter {
  padding: 20px;
  background-color: #1ea2ed;
  min-height: 100vh;
}

.helpCenter h3 {
  font-size: 24px;
  font-weight: 900;
  color: #696969;
  padding: 10x;
  text-align: center;
}

.helpCenter .accounts .ques p {
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
  margin: 5px;
  color: #1ea2ed;
}

.helpCenter .accounts .ans p {
  font-size: 16px;
  font-weight: 600;
  padding: 0px;
  margin: 5px;
  color: #696969;
}
#helpCenter .col-md-4 .list-group .list-group-item {
  font-size: 14px;
  font-weight: 600;
  padding: 20px;
}

#helpCenter .col-md-4 .list-group .list-group-item.active {
  background-color: #1ea2ed;
  border-color: #1ea2ed;
}
</style>
