const helpCenterData = {
  createAccountQuestions: [
    {
      question: `Creating an account in ezygo.app`,
      answer: `Let’s get started with creating an account for you with ezygo.app. The first step before you can create your institute, enrol yourself to an institute or do any task on ezygo.app is to create your personal account. Complete your account creation process by filling all the necessary fields in the sign-up form. After getting the success message, you can proceed to sign-in to your ezygo account.`,
      image: `create_account.jpg`,
    },
    {
      question: `Updating email/mobile number prior account verification`,
      answer: `In case you have updated a wrong email or mobile number while creating an account and got to know it only on the mobile/email verification page, you can change it in the verification page itself as shown. Click on the edit icon next to mobile number/email and then enter the correct details. Wait for verification OTP and submit. Your email/mobile has been updated successfully`,
      image: `update_email_phone.jpg`,
    },

    {
      question: `Updating email/mobile number after account verification`,
      answer: `To change email/mobile number, click on your profile username on the navigation bar, click on ‘edit profile’. Click on the edit icon next to mobile number/email and then enter the correct details. Wait for verification OTP and submit. Your email/mobile has been updated successfully`,
      image: null,
    },
    {
      question: `Resetting ezygo.app account password`,
      answer: {
        answer1: `To reset the password of your ezygo.app account, enter the username/email address/ mobile number of your account and click on ‘submit’. If an account exists, the username will be displayed and a field to enter the password would appear. The ‘Reset Password’ link can be found below the Password field. `,
        image1: `reset_pass1.jpg`,
        answer2: `Click on the link, and the page would display the email address and mobile number. Select the option to which the password reset link needs to be sent and proceed as per the instructions received.`,
        image2: `reset_pass2.jpg`,
      },
      image: null,
    },
  ],
  createInstituteQuestions: [
    {
      question: `Types of Institute-Physical/virtual institute creation and verification`,
      answer: {
        answer1: `Now that you have successfully created your ezygo account, you can sign-in to create an institute with ezygo.app. Institutes in ezygo are of two types – `,
        image1: null,
        answer2: `<b>Physical Institute:</b> This option must be selected only if you are trying to create an ezygo account for an institute that exists physically. To proceed with a physical institute creation, the user will have to submit the institute’s physical address, official phone number and official email address. The user can also update the institute’s website address if it exists. <br> <b>Virtual Institute:</b> You may also create a virtual institute with ezygo.app to carry out tasks using features provided by ezygo.app. Virtual institute should only be created if the account is being created for an institute that doesn’t exist physically. All the features available for a physical institute are available for a virtual institute as well.`,
        image2: null,
      },

      image: null,
    },
    {
      question: `Inviting users (teachers/students) to your institute`,
      answer: {
        answer1: `<b>Inviting Instructors and Students from Institute Admin Login </b> <br> Your institute has been created successfully and now you need to invite other teachers and students so that you can start teaching and collaborating with your students. Click on the ‘Invite Users’ widget or the ‘Invite Users’ button under ‘Staff, Student List’ tab. Select the user role from the dropdown menu and enter the mobile number/email address of the users you would like to invite to the institute. Click on ‘send invitation’. A success message will be displayed and enrolment code will be send by sms/email based on the option you have selected. <br> <b>Inviting Students from Subgroup Admin Login</b> <br> Subgroup Admins can invite the students directly to their subgroup without having to invite the students to the institute first. All the students thus joining the subgroup can also be found in institution users list. <br> To invite a student to a subgroup, select the ‘enrol/invite/enrolled users’ option in subgroup.`,
        image1: `invitingStudentsSubgroup.jpg`,
        answer2: `Select ‘invite users’ and enter the mobile number/email address of the users you would like to invite to the subgroup. Click on ‘send enrolment code’. A success message will be displayed and enrolment code will be send by sms/email based on the option you have selected.`,
        image2: `invitingStudentsSubgroup1.jpg`,
      },
      image: null,
    },

    {
      question: `Joining an institute using enrolment code received by sms/email`,
      answer: `To join the institute, the user has to login to their own personal account with ezygo.app and enter the enrolment code(received by sms/email) in the page that appears (in case the user is not already a part of another institute) or in the field provided in the personal profile card.  `,
      image: null,
    },
  ],

  createGroupSubgroupQuestions: [
    {
      question: `Creating Groups/Subgroups-For Institution Admins`,
      answer: `Groups and Subgroups can be created from the ‘Group/subgroup’ tab. Subgroups are created under a particular group. Each group and subgroup can be assigned an ‘Admin’, who will have full control of that particular group/subgroup. Instructors can be added to groups (representing departments in an institution) and students can be added to subgroups (representing classes in an institution).`,

      image: null,
    },
    {
      question: `Adding Courses`,
      answer: `Courses are added to a subgroup and students from the subgroup can be set to be automatically enrolled to the course (as per the configuration in institution settings). Students can also be manually added to or removed from a particular course.  `,
      image: null,
    },
  ],
};

export default helpCenterData;
